<template>
  <div class="skeleton-box" v-loading="!isLogin">
    <VpPlayer
      ref="VpPlayer"
      v-if="isLogin"
      @playbackFailed="handlePlaybackFailed" @startPlay="handleStartPlay" @onHandover="handleOnHandover"
      :videoList="videoList"
    />
  </div>
</template>

<script>
import VpPlayer from "@/views/VideoPolling/VpPlayer";
import { loadGroups, loadVehicles } from "@/api/foreign";
import { getTreeNodeId } from "@/utils/treeHelper";
export default {
  components: { VpPlayer },
  computed: {
    isLogin() {
      return this.$store.state.userInfo ? true : false;
    },
    videoList() {
      const { checkedList } = this;
      return checkedList.map((obj) => this.toChannels(obj)).flat();
    },
  },
  data() {
    return {
      groupList: [],
      checkedList: [],
      timing: null,
      playbackFailedList: [],
      timeOut: null,
    };
  },
  created() {
    this.login();
  },
  deactivated() {
    clearInterval(this.timing);
    this.timing = null;
  },
  destroyed() {
    clearInterval(this.timing);
    this.timing = null;
  },
  methods: {
    /**
     * userName: 登录名
     * password: 登录密码
     * tenantId: 公司代码
     * verifyCode: 验证码
     * redirect: 目标路由地址
     */
    async login() {
      try {
        this.loginLoading = true;
        const params = {
          userName: "中国石油",
          password: "123456",
          tenantId: "KEHU",
          // userName: "广东一立科技",
          // password: "QWERASDF787458",
          // tenantId: "000000",
          code: "bysk2020",
        };
        await this.$store.dispatch("foreignLogin", params);
        try {
          let result = await loadGroups();
          this.groupList = result.obj.filter(
            (p) => p.groupId !== 0 && p.parentId !== 0
          ); //筛选掉监管车组
          this.timing ? (clearInterval(this.timing), (this.timing = null)) : "";
          this.timing = setInterval(() => {
            this.handleGetVehicles();
          }, 30000);
          this.handleGetVehicles();
        } catch (error) {
          console.log("车组查询失败");
        }
      } catch (err) {}
    },
    async handleGetVehicles() {
      const resultVehicles = await loadVehicles({
        groupIds: this.groupList.map((val) => val.groupId),
      });
      // 筛选离线及没ACC车辆
      const vehicles = resultVehicles?.obj?.data.filter(
        (obj) =>
          [0,1, 2, 3].includes(obj.Z) &&
          obj.ACC == 0 && !obj.BD &&
          !this.playbackFailedList.includes(obj.V)
      );
      if (vehicles.length <= 0) {
        this.$message.error('所有设备均已离线!');
        return false;
      }
      // 测试车辆开始
      // const vehicles = [{
      //     "PT": "2024-04-11 15:27:54",
      //     "pos_Staue": "无",
      //     "pmt": 1712820473713,
      //     "groupName": "中国石油黑龙江",
      //     "maintenanceMode": "0",
      //     "STSTA": 0,
      //     "G": 1,
      //     "machineryProductType": "excavating_machinery",
      //     "L": 100,
      //     "M": 9758,
      //     "N": "20231219003",
      //     "P": "吊管机+7-9-193",
      //     "R": "2024-04-11 15:27:54",
      //     "T": "GB-1078",
      //     "V": 15329,
      //     "X": 131.738012,
      //     "Y": 45.746596,
      //     "Z": 2,
      //     "SFP": 0,
      //     "gsmSin": 22,
      //     "ACC": 0,
      //     "loadState": "0",
      //     "recorderSpeed": 0,
      //     "todaymileage": "0.0",
      //     "loadStatus": 0,
      //     "camreaLine": "[2]",
      //     "machineryEquipmentTypeStr": "履带挖掘机",
      //     "EP": "2099-12-31 23:59:59",
      // percentageOfFlow: 0,
      //     "SIM": "8986112221904793387",
      //     "NOW": "2024-04-11 15:28:12",
      //     "frameNo": "7-9-193",
      //     "hisiOnline": 0,
      //     "company": null,
      //     "productType": 1,
      //     "voltage": "28.60",
      // },{
      //     "PT": "2024-04-11 15:27:54",
      //     "pos_Staue": "无",
      //     "pmt": 1712820473713,
      //     "groupName": "中国石油黑龙江",
      //     "maintenanceMode": "0",
      //     "STSTA": 0,
      //     "G": 1,
      //     "machineryProductType": "excavating_machinery",
      //     "L": 100,
      //     "M": 9761,
      //     "N": "20240330027",
      //     "P": "吊管机+7-9-193",
      //     "R": "2024-04-11 15:27:54",
      //     "T": "GB-1078",
      //     "V": 15298,
      //     "X": 131.738012,
      //     "Y": 45.746596,
      //     "Z": 2,
      //     "SFP": 0,
      //     "gsmSin": 22,
      //     "ACC": 0,
      //     "loadState": "0",
      //     "recorderSpeed": 0,
      // percentageOfFlow: 0,
      //     "todaymileage": "0.0",
      //     "loadStatus": 0,
      //     "camreaLine": "[1,2,3,4]",
      //     "machineryEquipmentTypeStr": "履带挖掘机",
      //     "EP": "2099-12-31 23:59:59",
      //     "SIM": "8986112221904793387",
      //     "NOW": "2024-04-11 15:28:12",
      //     "frameNo": "7-9-193",
      //     "hisiOnline": 0,
      //     "company": null,
      //     "productType": 1,
      //     "voltage": "28.60",
      // }]
      // // 测试车辆结束
      console.log(vehicles, this.playbackFailedList, '---------------------');
      if (
        this.checkedList.length &&
        vehicles
          .map((val) => val.V)
          .includes(this.checkedList[0].V)
      ) {
        return false;
      } else if (this.checkedList.length) {
        this.$message.error("车辆状态异常,正在切换车辆！");
        this.$refs.VpPlayer.onStart();
        this.$nextTick(() => {
          this.checkedList = vehicles.length ? [vehicles[0]] : [];
          this.$nextTick(() => {
            this.handleVideoPlay();
          });
        });
        return false;
      }
      this.checkedList = vehicles.length ? [vehicles[0]] : [];
      this.$nextTick(() => {
        this.handleVideoPlay();
      });
    },
    handleVideoPlay() {
      this.$refs.VpPlayer.totalPlayTime = 99999999;
      this.$nextTick(() => {
        this.$refs.VpPlayer.onStart();
        // this.timeOut = setTimeout(() => {
        //   this.handlePlaybackFailed();
        //   this.$nextTick(()=>{
        //     this.handleGetVehicles();
        //   })
        // }, 15000)
      });
    },
    toChannels(data) {
      const channels = this.parseChannels(data.camreaLine);
      const cameraCH = this.parseChannels(data.cameraCH);
      // 终端号
      const device = data.N;
      // 设备协议类型 1: GF-1078, GB-1078; 2: GA系列
      const protocolType = ["GF-1078", "GB-1078"].includes(data.T) ? 1 : 2;
      // 特殊协议 0: 不处理; 1: 粤标
      const SpecialSign = data.percentageOfFlow === 6 ? 1 : 0;
      // 设备名称
      const plate = data.P;
      // 车辆Id
      const vehicleId = data.V;
      // 车组Id
      const groupId = data.M;

      return channels.map((channel, ind) => {
        const key = getTreeNodeId(data.M, data.V, channel);
        return {
          key,
          channel,
          channelsName: cameraCH[ind],
          device,
          protocolType,
          SpecialSign,
          plate,
          vehicleId,
          groupId,
        };
      });
    },
    parseChannels(channelStr) {
      try {
        return JSON.parse(channelStr);
      } catch (err) {
        //
      }
      return [];
    },
    handlePlaybackFailed() {
      this.playbackFailedList.push(this.checkedList[0].V);
    },
    handleStartPlay() {
      this.timeOut ? (clearTimeout(this.timeOut), this.timeOut = null) : ''
      console.log('视频开始播放！');
    },
    handleOnHandover() {
      this.handlePlaybackFailed();
        this.$nextTick(()=>{
          this.handleGetVehicles();
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.skeleton-box {
  height: 100%;
  ::v-deep .Header {
    display: none !important;
  }
}
</style>