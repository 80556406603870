<template>
  <div class="VpPlayerWrapper">
    <div class="Header">
      <el-select
        class="wpx140 mr10"
        v-model="playChannels"
        :disabled="isPolling"
        v-if="!isCustomization"
        multiple
        collapse-tags
      >
        <el-option
          v-for="channelObj in channelOptions"
          :key="channelObj.value"
          :value="channelObj.value"
          :label="channelObj.label"
        ></el-option>
      </el-select>

      <span>
        <label class="mr10">每次播放</label>
        <el-select
          class="wpx100 mr10"
          v-model="videoGrid"
          :disabled="isPolling"
        >
          <el-option
            v-for="gridObj in videoGridOptions"
            :key="gridObj.value"
            :value="gridObj.value"
            :label="gridObj.label"
          ></el-option>
        </el-select>
      </span>

      <span class="mr10">
        <label class="mr10">时长</label>
        <el-input-number
          class="wpx100"
          v-model="playTime"
          :disabled="isPolling"
          :min="5"
          :max="totalPlayTime * 60"
          :step="1"
          controls-position="right"
        ></el-input-number>
        <label>(秒)</label>
      </span>

      <span class="mr10">
        <label class="mr10">总时长</label>
        <el-input-number
          class="wpx100"
          v-model="totalPlayTime"
          :disabled="isPolling"
          :min="5"
          :max="1440"
          :step="1"
          controls-position="right"
        ></el-input-number>
        <label>(分钟)</label>
      </span>

      <el-button-group>
        <el-button
          type="primary"
          :disabled="!playList.length"
          @click="onStart"
        >{{ isPolling ? '停止轮询' : '开始轮询'}}</el-button>
        <el-button
          :disabled="!isPolling"
          type="primary"
          @click="onPause"
        >{{ isPaused ? '继续轮询' : '暂停轮询'}}</el-button>
        <el-button
          type="primary"
          :disabled="pageTotal <= 1 || pageCurrent <= 1"
          @click="onPrevClick"
        >上一页</el-button>
        <el-button
          type="primary"
          :disabled="pageTotal <= 1 || pageCurrent >= pageTotal"
          @click="onNextClick"
        >下一页</el-button>
      </el-button-group>
    </div>

    <div class="Content">
      <div :id="playerId" class="playerContainer" ref="playerRef"></div>
    </div>
  </div>
</template>

<script>
import { throttle } from 'lodash';
import { mapState } from 'vuex';
import { appConfig } from '@/config/appConfig';
import { randomNumber } from '@/utils/random';
import { sleep } from '@/utils/sleep';
import ByskPlayer from '@/components/ByskPlayer';
import { diffPatch } from '@/utils/diffPatch';
import { getTreeNodeId } from '@/utils/treeHelper';

// 通道
const channelOptions = [
  { value: 1, label: '通道1' },
  { value: 2, label: '通道2' },
  { value: 3, label: '通道3' },
  { value: 4, label: '通道4' },
  { value: 5, label: '通道5' },
  { value: 6, label: '通道6' },
  { value: 7, label: '通道7' },
  { value: 8, label: '通道8' }
];


const videoGridOptions = [
  { value: 1, label: '1行x1列' },
  { value: 4, label: '2行x2列' },
  { value: 9, label: '3行x3列' },
  { value: 16, label: '4行x4列' },
  { value: 25, label: '5行x5列' },
];


export default {
  name: 'VpPlayer',

  props: {
    // 播放视频列表
    videoList: {
      type: Array,
      default() {
        return [];
      }
    },
    videoGridOptions: {
      type: Array,
      default: ()=> [
        { value: 1, label: '1行x1列' },
        { value: 4, label: '2行x2列' },
        { value: 9, label: '3行x3列' },
        { value: 16, label: '4行x4列' },
        { value: 25, label: '5行x5列' },
      ]
    },
    definitionPlayTime: {
      type: Number,
      default: 10
    },
    isCustomization: {
      type: Boolean,
      default: false
    }
  },

  data() {
    this.playerObj = null;
    this.channelMap = new Map();
    this.timerId = null;
    this.pollingTimerId = null;

    return {
      channelOptions,
      playerId: `live-${randomNumber()}`,
      // 播放通道号 0: 全部
      playChannels: [1, 2, 3, 4],
      // 视频布局
      videoGrid: 4,
      // 播放时长 秒
      playTime: 10,
      // 播放总时长 分钟
      totalPlayTime: 30,
      // 当前页
      pageCurrent: 1,
      // 是否正在轮询
      isPolling: false,
      // 是否暂停
      isPaused: false,

      // 轮询通道列表
      pollingList: [],
      timeOut: null,
      isLLL: false,
      velocityTime: null,
      velocityAisle: null,
    };
  },
  computed: {
    ...mapState(['userInfo', 'appToken', 'userConfig']),
    pageSize() {
      const { videoGrid } = this;
      return videoGrid;
    },
    visibleList() {
      const { isPolling, videoList, pollingList, playChannels } = this;

      if (isPolling) {
        return pollingList.filter(obj => playChannels.includes(obj.channel));
      }
      return videoList.filter(obj => playChannels.includes(obj.channel));
    },
    pageTotal() {
      const { visibleList, pageSize } = this;
      if (visibleList.length === 0) return 0;
      return Math.ceil(visibleList.length / pageSize);
    },
    // 正在播放列表
    playList() {
      const { visibleList, pageCurrent, pageSize } = this;

      const startIndex = Math.max(0, (pageCurrent - 1) * pageSize);
      return visibleList.slice(startIndex, (startIndex + pageSize >= visibleList.length ? visibleList.length : startIndex + pageSize));
    }
  },
  watch: {

    videoGrid(val) {
      const { playerObj } = this;
      playerObj && playerObj.setPlayerNum(val);
    },

    async pageCurrent(val) {
      await this.$nextTick();
      const { isPolling, isPaused, playList } = this;
      if (!playList.length) return;

      if (val >= 1 && isPolling) {
        await this.startPolling();

        if (!isPaused) {
          this.stopTiming();
          this.startTiming();
        }
      }
    },
    playList: {
      handler(va){
        if (this.isCustomization && va.length && !this.isPolling && this.isLLL) {
          console.log('cess------------------------------------------');
          this.isLLL = false;
          this.$nextTick(()=>{
            this.onStart();
          })
        }
      },
      deep: true
    },
    isCustomization: {
      handler(v) {
        if (v) {
          this.playTime = 60;
          this.playChannels = [1,2,3,4,5,6,7,8];
        }
      },
      immediate: true
    }
  },
  beforeMount() {
    this.onPlayStart = this.onPlayStart.bind(this);
    this.onPlayEnd = this.onPlayEnd.bind(this);
    this.onRegister = this.onRegister.bind(this);
    this.onPlayerMessage = this.onPlayerMessage.bind(this);
    this.onStartPlay = this.onStartPlay.bind(this);
  },
  mounted() {
    this.initPlayer();
    this.isLLL = true;
    // 获取系统设置的值
    // 轮询通道号
    // this.playChannels = this.userConfig.videoPollingConfig.pollingChannels;
    // 视频窗口布局
    this.videoGrid = this.userConfig.videoPollingConfig.videoGrid;
    // 播放时长
    // this.playTime = this.userConfig.videoPollingConfig.playTime;
    // 总时长
    this.totalPlayTime = this.userConfig.videoPollingConfig.totalPlayTime;
  },
  beforeDestroy() {

    this.stopPolling();

    const { playerObj } = this;
    if (playerObj) {
      this.closeAll();
      playerObj.destroy();
    }
    this.playerObj = null;
    this.channelMap.clear();

  },
  methods: {
    handleTimeLimit() {
      this.timeOut = setTimeout(() => {
        this.stopTiming();
        if (this.pageCurrent >= this.pageTotal) {
          this.pageCurrent = 0;
        }
        this.pageCurrent += 1;
        clearTimeout(this.timeOut);
        this.timeOut = null;
        this.handleTimeLimit();
        this.startTiming();
      }, 15000)
    },
    startTiming() {
      const { playTime } = this;
      this.pollingTimerId = setTimeout(() => {
        if (this.pollingTimerId && this.timerId) {

          if (this.pageTotal <= 0) {
            this.stopPolling();
            return;
          }

          if (this.pageCurrent >= this.pageTotal) {
            this.pageCurrent = 0;
          }
          this.pageCurrent += 1;
        }
      }, playTime * 1000);
    },
    stopTiming() {
      clearTimeout(this.pollingTimerId);
      clearTimeout(this.timeOut);
      this.timeOut = null;
      this.pollingTimerId = null;
    },

    /**
     * 开始轮询
     */
    async startPolling() {
      const { playList, channelMap } = this;

      try {
        if (channelMap.size > 0) {
          this.closeAll();
          await sleep(500);
        }

        console.log('start polling', playList);

        playList
          .forEach((channelObj, index) => {
            this.playChannelVideo(channelObj, index);
          });
      } catch (err) {
        //
      }

    },

    // 结束轮询
    stopPolling() {
      this.isPolling = false;
      this.isPaused = false;
      this.pageCurrent = 1;
      this.pollingList = [];
      const { channelMap } = this;
      clearTimeout(this.pollingTimerId);
      clearTimeout(this.timerId);
      clearTimeout(this.timeOut);
      this.timeOut = null;
      this.pollingTimerId = null;
      this.timerId = null;
      if (channelMap.size > 0) {
        this.closeAll();
      }
    },

    // 打开视频
    playChannelVideo(channelObj, videoPos = 0) {
      return new Promise((resolve) => {

        const { playerObj } = this;

        if (!playerObj) return resolve(false);

        if (this.channelMap.has(channelObj.key)) return resolve(false);

        let { flag, ids } = playerObj.allocate(1, videoPos);

        if (!(flag && ids.length)) return resolve(false);
        const tid = ids[0];

        this.channelMap.set(channelObj.key, {
          tid,
          ...channelObj
        });
        this.playerObj.real.open(
          tid,
          {
            device: channelObj.device,
            channel: channelObj.channel,
            channelsName: channelObj.channelsName,
            protocolType: channelObj.protocolType,
            SpecialSign: channelObj.SpecialSign,
            codeTypeCtrlBtnEnabled: true,
            plate: channelObj.plate,
            vehicleId: channelObj.vehicleId,
            groupId: channelObj.groupId,
          },
          (res) => {
            if (![1, 2].includes(res.status)) {
              this.$emit('playbackFailed')
              return resolve(false);
            }
            return resolve(true);
          }
        );
      });
    },
    // 关闭视频
    closeChannelVideo(channelObj) {
      if (!this.channelMap.has(channelObj.key)) return;
      this.channelMap.delete(channelObj.key);
      this.playerObj.real.close(null, {
        device: channelObj.device,
        channel: channelObj.channel,
        protocolType: channelObj.protocolType,
        isEmpty: true,
        replayBtnHide: true
      });
    },

    async initPlayer() {
      await this.$nextTick();

      const { playerId, userInfo, appToken, videoGrid } = this;
      console.log(this.$route.name == 'foreignBigScreenDisplayVideoPolling' || this.$route.name == 'foreignVideoPolling' ? 'wss://120.78.185.121:8899' : appConfig.baseMediaUrl);
      const baseURL = this.$route.name == 'foreignBigScreenDisplayVideoPolling' || this.$route.name == 'foreignVideoPolling' ? 'wss://120.78.185.121:8899' : appConfig.baseMediaUrl;

      const player = new ByskPlayer({
        id: playerId,
        isReal: true,
        baseURL,
        userId: userInfo.name,
        userkey: appToken,
        playstart: this.onPlayStart,
        playend: this.onPlayEnd,
        register: this.onRegister,
        startPlay: this.onStartPlay,
        onVelocity: this.onVelocity,
        message: this.onPlayerMessage,
      });

      this.playerObj = player;

      await this.$nextTick();

      player.setPlayerNum(videoGrid);
      // player.autoCloseTime = 10;
    },
    onPlayStart(obj) {
      const key = getTreeNodeId(obj.groupId, obj.vehicleId, obj.channel);
      const channelObj = {
        key,
        device: obj.device,
        channel: obj.channel,
        channelsName: obj.channelsName,
        protocolType: obj.protocolType,
        plate: obj.plate,
        vehicleId: obj.vehicleId,
        groupId: obj.groupId,
      };
      this.channelMap.set(key, {
        tid: obj.vid,
        ...channelObj,
      });
    },
    onPlayEnd(obj) {
      const key = getTreeNodeId(obj.groupId, obj.vehicleId, obj.channel);
      const channelObj = this.channelMap.get(key);
      this.channelMap.delete(key);
    },
    onRegister() {

    },
    onPlayerMessage(obj) {
    },
    closeAll() {
      const { channelMap } = this;
      const channelObjs = [...channelMap.values()];
      channelObjs
        .forEach(channelObj => this.closeChannelVideo(channelObj));
    },

    async onStart() {
      const isPolling = !this.isPolling;
      this.isPolling = isPolling;
      const { totalPlayTime, videoList } = this;
      if (isPolling) {
        this.pollingList = [...videoList];
        await this.startPolling();
        this.handleTimeLimit();
        this.startTiming();

        this.timerId = setTimeout(() => {
          this.stopPolling();
        }, totalPlayTime * 60 * 1000);

      } else {
        this.stopPolling();
      }

    },
    onPause() {
      const isPaused = !this.isPaused;
      this.isPaused = isPaused;

      if (isPaused) {
        this.stopTiming();
      } else {
        this.startTiming();
      }
    },
    onPrevClick() {
      this.stopTiming();
      this.pageCurrent -= 1;
      if (this.pageCurrent <= 0) {
        this.pageCurrent = 1;
      }
    },
    onNextClick() {
      this.stopTiming();
      this.pageCurrent += 1;
      if (this.pageCurrent > this.pageTotal) {
        this.pageCurrent = 1;
      }
    },
    onStartPlay() {
      // this.timeOut ? (clearTimeout(this.timeOut), this.timeOut = null) : '';
      this.$emit('startPlay')
    },
    onVelocity(e, channel) {
      if (!e && !this.velocityAisle) {
        this.velocityAisle = channel;
        this.velocityTime = setTimeout(()=> {
          this.velocityAisle = null;
          clearTimeout(this.velocityTime);
          this.velocityTime = null;
          this.$emit('onHandover');
          this.onVelocity(this.velocityAisle,0);
        }, 15000)
      }else if (this.velocityAisle && this.velocityAisle == channel && e) {
        this.velocityAisle = null;
        clearTimeout(this.velocityTime);
        this.velocityTime = null;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.VpPlayerWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

.Header {
  height: 40px;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.Content {
  flex: 1;
  overflow: hidden;
}
.playerContainer {
  flex: 1;
  height: 100%;
  width: 100%;
  display: block;
}

.wpx80 {
  width: 80px;
}
.wpx100 {
  width: 100px;
}
.wpx120 {
  width: 120px;
}
.wpx140 {
  width: 140px;
}
.mr10 {
  margin-right: 10px;
}
</style>